import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Commons from '../../Helpers/commons'
import { InvoiceItemModel, NoteCreditCreationRequest, NoteCreditResponse } from '@/models/invoice-models'
import { InvoiceService } from '@/services/invoice-service'

@Component({
  components: {
    Alert,
    ValidationObserver,
    ValidationProvider
  }
})
export default class NoteCreditDialog extends Vue {
  private invoiceService = InvoiceService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: null })
  public item?: NoteCreditResponse

  @Prop()
  public invoiceId!: number

  public state: NoteCreditCreationRequest = {
    date: '',
    reason: '',
    invoiceItems: []
  }

  public isNew = true

  public isSaving = false
  private errorMessages: string[] = []
  private loading = false
  public itemsHeader: any[] = []
  public items: InvoiceItemModel[] = []

  @Watch('visible') onVisibleChange (v) {
    if (v) {
      this.resetDialog()

      this.loading = true
      this.invoiceService.getInvoiceItems(this.invoiceId)
        .then((items) => {
          this.items = items

          if (this.item) {
            this.state.date = Commons.FormatDateForInputField(new Date(this.item.date))
            this.state.reason = this.item.reason
            this.state.invoiceItems = this.item.invoiceItems
            this.isNew = false
          } else {
            this.state.date = Commons.FormatDateForInputField(new Date())
            this.state.reason = ''
            this.state.invoiceItems = this.items
            this.isNew = true
          }
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  public mounted () {
    this.resetDialog()
    this.itemsHeader = [
      {
        text: 'Date',
        value: 'date',
        width: '10%'
      },
      {
        text: 'Tarif',
        value: 'tarif',
        width: '5%'
      },
      {
        text: 'Code',
        value: 'code',
        width: '15%'
      },
      {
        text: 'Désignation',
        value: 'label',
        width: '35%'
      },
      {
        text: 'Quantité',
        value: 'quantity',
        width: '5%'
      },
      {
        text: 'Pt PM/prix',
        value: 'points',
        width: '10%'
      },
      {
        text: 'VPt PM',
        value: 'value',
        width: '10%'
      },
      {
        text: 'Montant',
        value: 'amount',
        width: '10%'
      }
    ]
  }

  private resetDialog () {
    this.errorMessages = []
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) {
      observer.reset()
    }
  }

  get show () {
    return this.visible
  }

  public close (didCreate: boolean) {
    this.$emit('close', didCreate)
  }

  public async save () {
    const prestationValidator = this.$refs.prestationValidator as InstanceType<typeof ValidationProvider>
    if (this.state.invoiceItems.length <= 0) {
      // apparently we can just pass an empty array to trigger the error state, if will generate the message from the rule(s)
      // that are configured
      prestationValidator.setErrors([])
    }
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      if (!this.isNew) {
        this.errorMessages = ['Il n\'est pas possible d\'éditer une note de crédit existante.']
        return
      }
      this.isSaving = true
      const response = await this.invoiceService.createNoteCredit(this.invoiceId, this.state)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (response) {
        this.close(true)
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }

  public get title () {
    const operation = this.isNew ? 'Création' : 'Visualisation'
    return `${operation} d'une note de crédit`
  }

  public handleToggleAll (e) {
    if (this.isNew) {
      if (e.value) {
        this.state.invoiceItems = this.items
      } else {
        this.state.invoiceItems = []
      }
    } else {
      this.rollbackSelection()
    }
  }

  public onItemSelected () {
    if (!this.isNew && this.item) {
      this.rollbackSelection()
    }
  }

  private rollbackSelection () {
    this.$nextTick(() => {
      if (this.item) {
        this.state.invoiceItems = this.item.invoiceItems
      }
    })
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1000px","persistent":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"article"}}),_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"actions"}})],1):_c('validation-observer',{ref:"observer"},[_c('v-card',[(!!_vm.errorMessages.length)?_c('v-card-text',[_c('v-card-text'),_c('alert',{attrs:{"value":!!_vm.errorMessages.length,"type":"error","canClose":true},on:{"close":function($event){return _vm.hideAlert()}}},[_c('ul',_vm._l((_vm.errorMessages),function(msg,idx){return _c('li',{key:("msg-" + idx)},[_vm._v(" "+_vm._s(msg)+" ")])}),0)])],1):_vm._e(),_c('v-card-title',[_c('h3',[_vm._v(_vm._s(_vm.title))])]),_c('v-card-text',[_c('v-container',[_c('form',[_c('v-row',[_c('c-col',[_c('text-input',{ref:"DateRef",attrs:{"label":"Date","name":"Date","type":"date","rules":"required","disabled":!_vm.isNew},model:{value:(_vm.state.date),callback:function ($$v) {_vm.$set(_vm.state, "date", $$v)},expression:"state.date"}})],1),_c('c-col',{attrs:{"md":8}},[_c('textarea-input',{ref:"MotifRef",attrs:{"label":"Motif","name":"Motif","rules":"required","disabled":!_vm.isNew},model:{value:(_vm.state.reason),callback:function ($$v) {_vm.$set(_vm.state, "reason", $$v)},expression:"state.reason"}})],1)],1),_c('v-row',[_c('h3',{staticClass:"subtitle"},[_vm._v("Liste des prestations qui seront à nouveau facturables")])]),_c('v-row',[_c('c-col',{attrs:{"md":12}},[_c('em',{staticClass:"grey--text float-right pb-2"},[_vm._v("Les prestations sélectionnées seront donc à nouveau facturables.")])])],1),_c('v-row',[_c('validation-provider',{ref:"prestationValidator",attrs:{"name":"prestations","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(errors.length > 0)?_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light error--text",attrs:{"role":"alert"}},[_c('div',{staticClass:"v-messages__wrapper"},[_c('div',{staticClass:"v-messages__message message-transition-enter-to"},[_vm._v(_vm._s(errors[0]))])])])]):_vm._e(),_c('c-data-table',{attrs:{"headers":_vm.itemsHeader,"items":_vm.items,"show-select":"","item-key":"id","item-class":"rowClass","loading":_vm.loading,"hide-default-footer":true,"items-per-page":-1},on:{"toggle-select-all":function($event){return _vm.handleToggleAll($event)},"item-selected":function($event){return _vm.onItemSelected($event)}},scopedSlots:_vm._u([_vm._l((_vm.itemsHeader.filter(function (header) { return header.hasOwnProperty('formatter'); })),function(header){return {key:("item." + (header.value)),fn:function(ref){
var header = ref.header;
var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("date-fr")(item.date))+" ")])]}}],null,true),model:{value:(_vm.state.invoiceItems),callback:function ($$v) {_vm.$set(_vm.state, "invoiceItems", $$v)},expression:"state.invoiceItems"}})]}}])})],1)],1)])],1),(_vm.isNew)?_c('v-card-actions',[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.close(false)}}},[_vm._v(" Annuler ")]),_c('c-btn',{staticClass:"btn-red",attrs:{"loading":_vm.isSaving},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Enregistrer ")])],1):_c('v-card-actions',[_c('c-btn',{staticClass:"btn-white",on:{"click":function($event){return _vm.close(false)}}},[_vm._v(" Fermer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
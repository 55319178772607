import { LayoutService } from '@/services/layout-service'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { facturationPageNavitems, billablePrestationTypes, invoiceModes, invoiceStatuses, CreatedStatus, InvoiceDocumentType } from '../../Constants'
import Alert from '@/components/shared/Alert.vue'
import { PatientService } from '@/services/patient-service'
import { ErrorService } from '@/services/error.service'
import Commons from '@/components/shared/Helpers/commons'
import { ValidationObserver } from 'vee-validate'
import { Subscription } from 'rxjs'
import { InvoiceResponse, InvoiceSearchModel, InvoiceModel, NoteCreditResponse } from '@/models/invoice-models'
import { InvoiceService } from '@/services/invoice-service'
import { dossierPatientModel } from '@/models/dossier-response-model'
import EditInvoiceDialog from '@/components/shared/Dialogs/EditInvoiceDialog/EditInvoiceDialog.vue'
import EditInvoiceStatusDialog from '@/components/shared/Dialogs/EditInvoiceStatusDialog/EditInvoiceStatusDialog.vue'
import Confirm from '@/components/shared/Confirm/confirm.vue'
import { defaultItemsPerPage, defaultItemsPerPageWithoutAll } from '@/shared/constants/Constants'
import { PagedCollection } from '@/components/shared/Helpers/common-models'
import InvoiceErrorDetailDialog from '@/components/shared/Dialogs/InvoiceErrorDetailDialog/InvoiceErrorDetailDialog.vue'
import FactureBase from '@/components/shared/DocumentPreview/FactureBase/FactureBase.vue'
import InvoiceHelpers from '@/components/shared/Helpers/InvoiceHelpers'
import { InvoicePdfModel } from '@/models/pdf-model'
import PdfHelpers from '@/components/shared/Helpers/PdfHelpers'
import NoteCreditDialog from '@/components/shared/Dialogs/NoteCreditDialog/NoteCreditDialog.vue'

@Component({
  components: {
    Alert,
    Confirm,
    EditInvoiceDialog,
    EditInvoiceStatusDialog,
    FactureBase,
    InvoiceErrorDetailDialog,
    NoteCreditDialog,
    ValidationObserver
  }
})
export default class Recherche extends Vue {
  private patientService = PatientService.getInstance()
  private layoutService = LayoutService.getInstance()
  private invoiceService = InvoiceService.getInstance()

  private navitems = facturationPageNavitems

  private billablePrestationTypes = billablePrestationTypes

  private invoiceModes = invoiceModes

  private invoiceStatuses = invoiceStatuses

  public searchModel: InvoiceSearchModel = {}
  private lastSearchRequest: InvoiceSearchModel = {}
  public assurances: any[] = []

  public subscription!: Subscription
  public headers: any[] = []
  public isSearching = false
  public assuranceLoading = true
  public items: InvoiceResponse[] = []
  public dbPatients: dossierPatientModel[] = []

  private readyPatients = true
  public alertMessages: string[] = []

  public selected: InvoiceResponse[] = []
  public selectedItem: InvoiceResponse|null = null
  public selectedNoteCredit: NoteCreditResponse|null = null
  public selectedNoteCreditInvoiceId: number|null = null
  public showConfirmDeleteInvoice = false
  public showConfirmDeleteNoteCredit = false
  private isDeletingInvoice = false
  private isDeletingNoteCredit = false
  public deleteErrorMessages: string[] = []
  public dialog = false
  public dialogStatus = false
  public dialogNoteCredit = false
  public alertType: 'success' | 'error' = 'success'
  public showAlert = false
  public defaultItemsPerPageWithoutAll = defaultItemsPerPageWithoutAll
  public defaultItemsPerPage = defaultItemsPerPage
  public options: any = {}
  public totalSize = 0
  public pageCount = 0
  public firstSearch = true
  public canPaginationTriggerSearch = false
  public searchPatient = ''
  public selectedPatient?: dossierPatientModel
  public isSendingEInvoice = false
  public invoiceDetailDialog = false
  public selectedItemForDetail: InvoiceResponse|null = null
  public invoicesForMassDownload: InvoiceModel[] = []
  public massDownloading = false
  public isExporting = false

  public grandTotal = '0.00'

  public invoiceReminderType = InvoiceDocumentType.InvoiceReminder
  public invoiceType = InvoiceDocumentType.Invoice
  public noteCreditType = InvoiceDocumentType.NoteCredit

  public mounted () {
    this.selectedItem = null
    this.dialog = false
    this.showConfirmDeleteInvoice = false
    this.showConfirmDeleteNoteCredit = false
    this.isDeletingInvoice = false
    this.isDeletingNoteCredit = false
    this.layoutService.updateDrawerList(this.navitems)

    this.assuranceLoading = true
    this.subscription = this.patientService.assuranceList$.subscribe((assurance: any[]) => {
      this.assurances = assurance
      if (!assurance.length) {
        this.patientService.getAllAssurance()
      } else {
        this.assuranceLoading = false
      }
    })

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    this.headers = [
      {
        text: 'ID',
        value: 'id',
        width: '3%'
      },
      {
        text: 'ID patient',
        value: 'patientCode',
        width: '5%'
      },
      {
        text: 'Patient',
        value: 'patientName',
        width: '16%'
      },
      {
        text: 'Prestation(s)',
        value: 'invoiceTypeIds',
        formatter: this.getTypeNames,
        sortable: false,
        width: '10%'
      },
      {
        text: 'Assurance',
        value: 'insuranceName',
        width: '16%'
      },
      {
        text: 'Montant',
        value: 'amount',
        formatter: m => m.toFixed(2),
        width: '5%'
      },
      {
        text: 'Date facture',
        value: 'date',
        formatter: Commons.TransformDateFormat,
        width: '8%'
      },
      {
        text: 'Facturation',
        value: 'mode',
        formatter: this.getModeName,
        width: '10%'
      },
      {
        text: 'Statut',
        value: 'status',
        sortable: false,
        width: '10%'
      },
      {
        text: 'Note Crédit',
        value: 'actionsNoteCredit',
        sortable: false,
        width: '10%'
      },
      {
        text: '',
        value: 'actionsFacture',
        sortable: false,
        align: 'right',
        width: '7%'
      }
    ]
    if (this.$route.params.replay === 'true') {
      const lastSearch = sessionStorage.getItem('lastInvoiceSearch')
      const lastSearchOptions = sessionStorage.getItem('lastInvoiceSearchOptions')
      if (lastSearch && lastSearchOptions) {
        this.searchModel = JSON.parse(lastSearch)
        this.options = JSON.parse(lastSearchOptions)
        this.firstSearch = false
        this.search()
        this.canPaginationTriggerSearch = true
      }
    }
  }

  public tableOptionsChanged () {
    if (this.canPaginationTriggerSearch) {
      this.search()
    }
  }

  public async buttonSearch () {
    this.canPaginationTriggerSearch = true
    await this.search()
    this.options.page = 1
  }

  public async search () {
    // prevent multiple searches from being sent, since searches can be issued from clicking the search button, v-data-table options changing, ...
    if (this.isSearching) {
      return
    }
    this.isSearching = true
    if (this.firstSearch) {
      this.firstSearch = false
      this.options.sortBy = ['date', 'patientName', 'id']
      this.options.sortDesc = [true, false, true]
    }
    sessionStorage.setItem('lastInvoiceSearch', JSON.stringify(this.searchModel))
    sessionStorage.setItem('lastInvoiceSearchOptions', JSON.stringify(this.options))
    const payload = {
      ...this.searchModel,
      patientId: this.searchModel.patient?.guid
    }
    delete payload.patient
    this.lastSearchRequest = payload
    this.invoiceService.searchInvoices(payload, this.options).then((p: PagedCollection<InvoiceResponse>) => {
      this.items = p.value
      this.totalSize = p.size
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.isSearching = false
    })
    this.invoiceService.getGrandTotalForInvoices(payload).then((total) => {
      this.grandTotal = InvoiceHelpers.formatNumberWithThousandSeparator(total)
    })
  }

  public getTypeNameById (typeId: number) {
    return this.billablePrestationTypes.find(x => x.value === typeId)?.text
  }

  public getTypeNames (typeIds: number[], separator = ', ') {
    return typeIds.map(s => this.getTypeNameById(s)).sort().join(separator)
  }

  public getStatusName (statusId: number) {
    return this.invoiceStatuses.find(x => x.value === statusId)?.text
  }

  public getStatusColor (statusId: number) {
    if (statusId === 7 || statusId === 9) {
      return 'primary' // red
    } else if (statusId === 6 || statusId === 10 || statusId === 11 || statusId === 13) {
      return 'green'
    } else {
      return ''
    }
  }

  public getModeName (modeId: number) {
    return this.invoiceModes.find(x => x.value === modeId)?.text
  }

  public formatDates (dates: string[], separator = ', ') {
    const copy = [...dates]
    return copy.sort((a, b) => new Date(a).getTime() - new Date(b).getTime()).map(d => Commons.TransformDateFormat(d)).join(separator)
  }

  public get sortedInvoiceStatuses () {
    return this.invoiceStatuses.sort((a, b) => a.text.localeCompare(b.text))
  }

  public get sortedBillablePrestationTypes () {
    return this.billablePrestationTypes.sort((a, b) => a.text.localeCompare(b.text))
  }

  public editInvoice (item: InvoiceResponse) {
    this.selectedItem = item
    this.dialog = true
  }

  public async closeDialog (didUpdate: boolean) {
    this.dialog = false
    this.selectedItem = null
    if (didUpdate) {
      this.updateAlertSuccessEditMessage('La facture a été modifiée avec succès')
    }
    await this.search()
  }

  public displayDeleteInvoiceConfirmDialog (item: InvoiceResponse) {
    this.deleteErrorMessages = []
    this.showConfirmDeleteInvoice = true
    this.selectedItem = item
  }

  public async confirmDeleteInvoiceCallback (value: boolean) {
    if (value && this.selectedItem) {
      this.isDeletingInvoice = true
      this.invoiceService.deleteInvoice(this.selectedItem.id)
        .then(() => {
          this.selectedItem = null
          this.showConfirmDeleteInvoice = false
          this.updateAlertSuccessDeleteMessage()
          this.search()
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.deleteErrorMessages = res.errors
        }).finally(() => {
          this.isDeletingInvoice = false
        })
    } else {
      this.showConfirmDeleteInvoice = false
      this.deleteErrorMessages = []
    }
  }

  private updateAlertSuccessDeleteMessage () {
    this.alertType = 'success'
    this.alertMessages = ["La facture a été supprimée avec succès"]
    this.showAlert = true
  }

  private updateAlertSuccessEditMessage (msg: string) {
    this.alertType = 'success'
    this.alertMessages = [msg]
    this.showAlert = true
  }

  private updateAlertMessage (res: { errors: any[]; title: string }) {
    this.alertMessages = res.errors
    this.alertType = 'error'
    this.showAlert = res.errors.length > 0
  }

  public viewPdf (id: number, documentType: InvoiceDocumentType) {
    this.$router.push({ name: 'InvoicePreview', params: { id: id.toString(), documentType: JSON.stringify(documentType) } })
  }

  public hideAlert () {
    this.alertMessages = []
    this.showAlert = false
  }

  public resetSearch () {
    this.searchModel = {}
  }

  public handleToggleAll (e) {
    if (e.value) {
      this.selected = this.items
    } else {
      this.selected = []
    }
  }

  public get canExportSage () {
    return this.selected.length > 0
  }

  @Watch('searchPatient')
  public searchPatientChanged (v) {
    if (v && v.length > 2) {
      this.refreshPatients()
    } else {
      this.dbPatients = []
    }
  }

  public patientChanged (e) {
    this.selectedPatient = this.dbPatients.find(g => g.guid.toLowerCase() === e?.guid?.toLowerCase())
  }

  public get patients () {
    if (this.selectedPatient?.fullName) {
      return [...this.dbPatients, this.selectedPatient].sort((a, b) => (a.fullName.toLocaleLowerCase()).localeCompare(b.fullName.toLocaleLowerCase()))
    }
    return this.dbPatients
  }

  public refreshPatients () {
    // bail early if a search is already in progress
    if (!this.readyPatients) {
      return
    }
    this.readyPatients = false
    const q = parseInt(this.searchPatient)
      ? `limit=-1&search=patientCode eq ${encodeURI(parseInt(this.searchPatient).toString())}`
      : `limit=-1&search=prenom,nom has ${encodeURI(this.searchPatient)}`
    this.patientService.getPatients(q).then((ds: any) => {
      this.dbPatients = ds.value
    }).catch(async (errs) => {
      const res = await ErrorService.handleError(errs)
      this.updateAlertMessage(res)
    }).finally(() => {
      this.readyPatients = true
    })
  }

  public displayPatient (item: dossierPatientModel) {
    return Commons.autocompleteDisplayPatient(item, this.searchPatient)
  }

  public sendEInvoices () {
    this.isSendingEInvoice = true
    this.invoiceService.generateAndSendEInvoices(this.lastSearchRequest)
      .then(async (n) => {
        let message
        switch (n) {
          case 0: {
            message = "Aucune facture n'avait le statut \"créé\"."
            break
          }
          case 1: {
            message = 'La facture qui avait le statut "créé" a bien été envoyée électroniquement.'
            break
          }
          default: {
            message = `Les ${n} factures qui avaient le statut "créé" ont bien été envoyées électroniquement.`
            break
          }
        }
        this.updateAlertSuccessEditMessage(message)
        await this.search()
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      }).finally(() => {
        this.isSendingEInvoice = false
      })
  }

  public isBillInError (invoice: InvoiceResponse) {
    return !!invoice.eInvoiceResponse || !!invoice.eInvoiceResponseStatus
  }

  public closeInvoiceDetailDialog () {
    this.invoiceDetailDialog = false
    this.selectedItemForDetail = null
  }

  public showInvoiceErrorDetail (invoice: InvoiceResponse) {
    this.selectedItemForDetail = invoice
    this.invoiceDetailDialog = true
  }

  public canDeleteInvoice (item: InvoiceResponse) {
    const deletableStatuses = [CreatedStatus]
    return deletableStatuses.indexOf(item.status) >= 0 && !item.exportSage
  }

  public editStatus () {
    this.dialogStatus = true
  }

  public async closeDialogStatus (didUpdate: boolean) {
    this.dialogStatus = false
    if (didUpdate) {
      this.updateAlertSuccessEditMessage('Les factures ont été modifées avec succès')
    }
    await this.search()
  }

  public massDownload () {
    this.massDownloading = true
    this.invoicesForMassDownload.length = 0

    const myPromises = this.selected.map(s => this.invoiceService.getInvoice(s.id))
    Promise.all(myPromises)
      .then(async invoices => {
        // since we are using hidden <facture-base> elements, the $forceUpdate call
        // is needed so that the refs of the <facture-base> can be rendered
        this.$forceUpdate()
        this.invoicesForMassDownload = invoices
        const models: InvoicePdfModel[] = []
        for (const invoice of invoices) {
          const ref = `massInvoiceRef-${invoice.id}`
          let documentPreview = this.$refs[ref] as any
          while (!documentPreview) {
            await Commons.sleep(100)
            documentPreview = this.$refs[ref] as any
          }
          while (!documentPreview[0].$refs) {
            await Commons.sleep(100)
          }
          const model = InvoiceHelpers.GeneratePDFModel(`Facture-${invoice?.id}-${invoice.date}.pdf`, documentPreview[0].$refs)
          models.push(model)
        }
        this.invoiceService.massDownload(models).then(f => {
          PdfHelpers.downloadFile(f)
        }).catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.updateAlertMessage(res)
        }).finally(() => {
          this.massDownloading = false
          // clear the <facture-base> elements from the DOM
          this.invoicesForMassDownload.length = 0
        })
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      })
  }

  public canDeleteNoteCredit (item: InvoiceResponse) {
    return !!item.noteCredit && !item.noteCredit.exportSage
  }

  public displayDeleteNoteCreditConfirmDialog (item: InvoiceResponse) {
    this.deleteErrorMessages = []
    this.showConfirmDeleteNoteCredit = true
    this.selectedNoteCredit = item.noteCredit ?? null
  }

  public async confirmDeleteNoteCreditCallback (value: boolean) {
    if (value && this.selectedNoteCredit) {
      this.isDeletingNoteCredit = true
      this.invoiceService.deleteNoteCredit(this.selectedNoteCredit.invoiceId)
        .then(() => {
          this.selectedNoteCredit = null
          this.showConfirmDeleteNoteCredit = false
          this.updateAlertSuccessDeleteMessage()
          this.search()
        })
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.deleteErrorMessages = res.errors
        }).finally(() => {
          this.isDeletingNoteCredit = false
        })
    } else {
      this.showConfirmDeleteNoteCredit = false
      this.deleteErrorMessages = []
    }
  }

  public createNoteCredit (item: InvoiceResponse) {
    this.selectedNoteCreditInvoiceId = item.id
    this.dialogNoteCredit = true
  }

  public openNoteCredit (item: InvoiceResponse) {
    if (item.noteCredit) {
      this.selectedNoteCreditInvoiceId = item.id
      this.selectedNoteCredit = item.noteCredit
    }
    this.dialogNoteCredit = true
  }

  public async closeNoteCreditDialog (didCreate: boolean) {
    this.dialogNoteCredit = false
    this.selectedNoteCredit = null
    this.selectedNoteCreditInvoiceId = null
    if (didCreate) {
      this.updateAlertSuccessEditMessage('La note de crédit a bien été créée')
    }
    await this.search()
  }

  public transformDate (d) {
    return Commons.TransformDateFormat(d)
  }

  public exportToExcel () {
    this.isExporting = true
    this.invoiceService.exportInvoicesToExcel(this.lastSearchRequest, this.options)
      .then(async (file) => {
        PdfHelpers.downloadFile(file)
      }).catch(async (errs) => {
        const res = await ErrorService.handleError(errs)
        this.updateAlertMessage(res)
      }).finally(() => {
        this.isExporting = false
      })
  }
}

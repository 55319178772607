import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { InvoiceResponse } from '@/models/invoice-models'

@Component({
  components: {
  }
})
export default class InvoiceErrorDetailDialog extends Vue {
  @Prop()
  public visible!: boolean

  @Prop({ default: null })
  public item!: InvoiceResponse

  get show () {
    return this.visible
  }

  public close () {
    this.$emit('close')
  }
}

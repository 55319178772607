import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { ErrorService } from '@/services/error.service'
import Alert from '@/components/shared/Alert.vue'
import { ValidationObserver } from 'vee-validate'
import Commons from '../../Helpers/commons'
import { InvoiceEditRequest, InvoiceResponse } from '@/models/invoice-models'
import { InvoiceService } from '@/services/invoice-service'
import { invoiceStatuses } from '@/views/Facturation/Constants'
@Component({
  components: {
    Alert,
    ValidationObserver
  }
})
export default class EditInvoiceDialog extends Vue {
  private invoiceService = InvoiceService.getInstance()

  @Prop()
  public visible!: boolean

  @Prop({ default: null })
  public item!: InvoiceResponse

  public state: InvoiceEditRequest = {}

  public isSaving = false
  private errorMessages: string[] = []
  private invoiceStatuses = invoiceStatuses

  @Watch('visible') onVisibleChange (v) {
    if (v) {
      this.resetDialog()
      this.state.id = this.item.id
      this.state.date = Commons.FormatDateForInputField(new Date(this.item.date))
      this.state.dateReminder = this.item.dateReminder ? Commons.FormatDateForInputField(new Date(this.item.dateReminder)) : undefined
      this.state.status = this.item.status
    }
  }

  public mounted () {
    this.resetDialog()
  }

  private resetDialog () {
    this.errorMessages = []
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    if (observer) {
      observer.reset()
    }
  }

  get show () {
    return this.visible
  }

  public close (didUpdate: boolean) {
    this.$emit('close', didUpdate)
  }

  public async save () {
    const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
    const isValid = await observer.validate()
    if (isValid) {
      this.isSaving = true
      const response = await this.invoiceService.editInvoice(this.state)
        .catch(async (errs) => {
          const res = await ErrorService.handleError(errs)
          this.errorMessages = res.errors
        })
        .finally(() => {
          this.isSaving = false
        })
      if (response) {
        this.close(true)
      }
    } else {
      Commons.focusFirstComponentWithError(observer, this.$refs, 'Ref')
    }
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
